import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faYoutube,
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import brandLogo from "../../assets/logos/EduladeLogo.png";

function Footer() {
  return (
    <div className="footer-container" id="Footer">
      <div className="footer-container-inner">
        <div className="footer-item">
          <a href="/" className="brand-logo">
            <img src={brandLogo} alt="Logo" />
          </a>
          <div className="connect">
            <h3>Connect with us:</h3>
            <div className="socials-container">
              <a href="#" target={"_blank"} className="social-icons">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a href="#" target={"_blank"} className="social-icons">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
              <a href="#" target={"_blank"} className="social-icons">
                <FontAwesomeIcon icon={faFacebookSquare} />
              </a>
              <a href="#" target={"_blank"} className="social-icons">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-item">
          <h3
            className="footer-title"
            id="ContactUs"
            style={{ textAlign: "center" }}
          >
            Links
          </h3>
          <a href="/#OurWork" className="footer-link">
            <p>Our Services</p>
          </a>
          {/* <a href="#" target={"_blank"} className="footer-link">
            <p>Business Partners</p>
          </a> */}
          <a href="/hirings"  className="footer-link">
            <p>Hirings</p>
          </a>
          <a href="/hirings" className="footer-link">
            <p>Internships</p>
          </a>
        </div>
        <div className="footer-item">
          <h3 className="footer-title" id="ContactUs">
            Contact Us
          </h3>
          <a
            href="mailto:info@edulade.com"
            target={"_blank"}
            className="contact-item"
          >
            <div className="contact-item-container social-icons">
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <p>info@edulade.com</p>
          </a>
          <a href="tel:+919552992828" className="contact-item">
            <div className="contact-item-container social-icons">
              <FontAwesomeIcon icon={faPhone} />
            </div>
            <p>+91 9552992828</p>
          </a>
          <a
            href="https://goo.gl/maps/YeVfc8YQQ9VayYQ2A"
            target={"_blank"}
            className="contact-item"
          >
            <div className="contact-item-container social-icons">
              <FontAwesomeIcon icon={faLocationDot} />
            </div>
            <p>Pimple Saudagar, Pune - 411027</p>
          </a>
        </div>
      </div>
      <p>
        Copyright ©2022 <a href="/">Edulade Technologies</a> . All rights
        reserved.
      </p>
    </div>
  );
}

export default Footer;
