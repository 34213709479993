import React from 'react';
import './Locate.css'

function Locate() {
  return <div className='locate-container' id='LocateUs'>
    <div className="container-title">
      <h2 className="title-primary">Locate</h2>
      <h3 className="title-secondary">Us</h3>
    </div>
    <div className="locate-container-inner">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15126.19851833186!2d73.7895661!3d18.5943324!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2026625001f496ff!2sEdulade%20Technologies!5e0!3m2!1sen!2sin!4v1654174935447!5m2!1sen!2sin" width="100%" height="450" style={{border: "0", margin: "auto"}} allowFullScreen={true} loading="lazy"></iframe>
    </div>
  </div>;
}

export default Locate;
