import React, { useState } from "react";
import emailjs from "emailjs-com";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./JobApply.css";
import Spinner from "../../assets/svg/spinner.svg";
import LoadingPage from "../LoadingPage/LoadingPage";

function JobApply({ showJobApply, setShowJobApply, currentTitle }) {
  const [fileLoading, setFileLoading] = useState(false);
  const [sentLoading, setSentLoading] = useState(false);
  const [applyData, setApplyData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    experience: "",
    qualification: "",
    hiringTitle: "",
    message: "",
  });
  const [files, setFiles] = useState(null);
  var HiringTitle = " ";
  if (currentTitle) {
    HiringTitle = currentTitle;
  }

  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setApplyData({ ...applyData, [name]: value });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setSentLoading(true);
    const body = {
      name: applyData.name,
      email: applyData.email,
      mobileNumber: applyData.mobileNumber,
      experience: applyData.experience,
      qualification: applyData.qualification,
      hiringTitle: currentTitle,
      message: applyData.message
        .split("\n")
        .join("<br>")
        .split(" ")
        .join("&nbsp;"),
    };
    emailjs
      .send("service_1jq405f", "template_j59244q", body, "bsZgrLW1IqM42Tt9l")
      .then(
        (result) => {
          setSentLoading(false);
          alert("Sent Successfully");
        },
        (error) => {
          setSentLoading(false);
          alert("Failed to Send!!!");
        }
      );
    e.target.reset();
  };

  return (
    <>
      <div className={`model-bg ${showJobApply ? "shown" : ""}`}></div>

      <div className={`job-apply-container  ${showJobApply ? "shown" : ""}`}>
        <span
          className="job-apply-close"
          onClick={() => setShowJobApply(false)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <div className={`job-apply-container-inner`}>
          <h1 className="title-primary">Apply Form</h1>
          {!sentLoading ? (
            <form className={`job-apply-form`} onSubmit={sendEmail}>
              <div className="job-apply-inputs">
                <input
                  type="name"
                  name="name"
                  id="name"
                  placeholder="Your Name"
                  onChange={handleInputs}
                  required
                />
                <label htmlFor="name">Your Name</label>
              </div>
              <div className="job-apply-inputs">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email Address"
                  onChange={handleInputs}
                  required
                />
                <label htmlFor="email">Email Address</label>
              </div>
              <div className="job-apply-inputs">
                <input
                  type="tel"
                  name="mobileNumber"
                  id="mobileNumber"
                  placeholder="Mobile Number"
                  onChange={handleInputs}
                />
                <label htmlFor="mobileNumber">Mobile Number</label>
              </div>
              <div className="job-apply-inputs">
                <input
                  type="text"
                  name="experience"
                  id="experience"
                  placeholder="Experience"
                  onChange={handleInputs}
                />
                <label htmlFor="experience">Experience</label>
              </div>
              <div className="job-apply-inputs">
                <input
                  type="text"
                  name="qualification"
                  id="qualification"
                  placeholder="Qualification"
                  onChange={handleInputs}
                />
                <label htmlFor="qualification">Qualification</label>
              </div>
              <div className="job-apply-inputs">
                <input
                  type="text"
                  name="hiringTitle"
                  id="hiringTitle"
                  placeholder={currentTitle}
                  value={HiringTitle}
                  readOnly
                />
                <label htmlFor="hiringTitle">Hiring Title</label>
              </div>
              <div className="job-apply-inputs">
                <textarea
                  type="text"
                  name="message"
                  id="message"
                  rows={6}
                  placeholder="Message"
                  onChange={handleInputs}
                ></textarea>
                <label htmlFor="message">Message</label>
              </div>

              <input
                type="submit"
                className="cta-button"
                disabled={fileLoading}
              />
            </form>
          ) : (
            <LoadingPage />
          )}
        </div>
      </div>
    </>
  );
}

export default JobApply;
