import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./Navbar.css";
import Logo from "../../assets/logos/EduladeLogoNoBG.png";

function Navbar({ showContact, setShowContact }) {
  var shownClassesNavbar = "navbar-items-container navbar-mobile show";
  var notShownClassesNavbar = "navbar-items-container navbar-mobile";
  var shownClassesNavBtn = "navbar-toggle show";
  var notShownClassesNavBtn = "navbar-toggle";
  const [shown, setShown] = useState(false);

  const NavItems = () => (
    <>
      <a href="/#" className="navbar-item">
        Home
      </a>
      <a href="/#services" className="navbar-item">
        Our Services
      </a>
      <Link to="/CaseStudies" className="navbar-item">
        Our Work
      </Link>
      <a href="/#AboutUs" className="navbar-item">
        About Us
      </a>
      <Link to={"/hirings"} className="navbar-item">
        Hirings
      </Link>
      <li href="#" className="navbar-item">
        Partners <FontAwesomeIcon icon={faAngleDown} />{" "}
        <div className="partners-container">
          <a
            href="https://www.aptechlearning.com/"
            target={"_blank"}
            rel="noopener noreferrer"
          >
            Aptech Learning
          </a>
          <a
            href="https://www.aptechaviationacademy.com/"
            target={"_blank"}
            rel="noopener noreferrer"
          >
            Aptech aviation
          </a>
          <a
            href="https://acarya.vercel.app/"
            target={"_blank"}
            rel="noopener noreferrer"
          >
            Acarya Technologies
          </a>
          <a href="https://chairs10.com/" target={"_blank"} rel="noopener noreferrer">
            Chalk&Chair
          </a>
          <a href="/#" target={"_blank"} rel="noopener noreferrer">
            Revive Technologies
          </a>
        </div>{" "}
      </li>
      <a href="#ContactUs" className="navbar-item">
        Contact Us
      </a>
      <button
        className="navbar-item cta-button"
        onClick={(e) => {
          setShowContact(true);
          e.preventDefault();
        }}
      >
        Get in touch
      </button>
    </>
  );

  return (
    <div className="navbar-container">
      <div className="navbar-container-inner">
        <div className="brand-logo">
          <Link to="/">
            <img src={Logo} alt="Edulade logo" />
          </Link>
        </div>
        <div className="navbar-items-container navbar-pc">
          <NavItems />
        </div>
        <button
          className={shown ? shownClassesNavBtn : notShownClassesNavBtn}
          onClick={() => setShown(!shown)}
        >
          <span></span>
        </button>
        <div className={shown ? shownClassesNavbar : notShownClassesNavbar}>
          <NavItems />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
