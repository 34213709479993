import React from 'react';
import './Hiring.css'

function Hiring() {
  return <div className='hiring-box' id='hirings'>
     <div className="hiring-box-inner">
       <h2>We Are Hiring!</h2>
       <p>We have many positions open for various works. to know more clock the button delow</p>
       <a href="/hirings" className="hiring-btn cta-button">Take Me There</a>
     </div>
  </div>;
}

export default Hiring;