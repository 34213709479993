import React from "react";
import "./Home.css";
import Header from "./Components/header/Header";
import What from "./Components/what/What";
import Work from "./Components/work/Work";
import About from "./Components/about/About";
import Hiring from "./Components/hiring/Hiring";
import Locate from "./Components/locate/Locate";

function Home( {showContact, setShowContact}) {
  return (
    <>
      <div className="main-container">
        <Header showContact = {showContact} setShowContact ={setShowContact} />
        <What />
        <Work />
        <About />
        <Hiring />
        <Locate />
      </div>
    </>
  );
}

export default Home;
