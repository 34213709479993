import React, { useEffect, useRef, useState } from "react";
import "./HiringPGAdmin.css";
import Header from "../../../Components/header/Header";

function HiringPGAdmin() {
  const formSelect = useRef();
  const [hiringsData, setHiringsData] = useState({
    jobTitle: "",
    jobType: "",
    jobTech: "",
    jobDiscription: "",
    jobOpenings: "",
  });
  const [updateHiringsData, setUpdateHiringsData] = useState({
    _id: "",
    jobTitle: "",
    jobType: "",
    jobTech: "",
    jobDiscription: "",
    jobOpenings: "",
  });
  const [gotHirings, setGotHirings] = useState(null);
  const [deleteHiring, setDeleteHiring] = useState(null);
  const [tempPass, setTempPass] = useState("");
  const [PassVerified, setPassVerified] = useState(false);
  var realAdminPass = "Team@Edulade1234";
  if (process.env.ADMINPASS) realAdminPass = process.env.ADMINPASS;
  // const apiUrl = "https://eduladecombackend.vercel.app";
  const apiUrl = process.env.APIURL || "https://api.edulade.com";

  useEffect(() => {
    getHirings();
  }, []);

  const hangleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setHiringsData({ ...hiringsData, [name]: value });
  };
  const hangleUpdateChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setUpdateHiringsData({ ...updateHiringsData, [name]: value });
  };

  const getHirings = async () => {
    try {
      const res = await fetch(`${apiUrl}/GetHirings`);
      const data = await res.json();
      setGotHirings(data);
    } catch (err) {
      console.log(err);
    }
  };
  const setHirings = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(`${apiUrl}/SetHirings`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          jobTitle: hiringsData.jobTitle,
          jobType: hiringsData.jobType,
          jobTech: hiringsData.jobTech,
          jobDiscription: hiringsData.jobDiscription,
          jobOpenings: hiringsData.jobOpenings,
        }),
      });
      if (res.status === 200) {
        window.alert("Hiring Added!");
        setHiringsData({
          jobTitle: "",
          jobType: "",
          jobTech: "",
          jobDiscription: "",
          jobOpenings: "",
        });
      } else {
        window.alert("Failed to add Hiring");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateHirings = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(`${apiUrl}/UpdateHirings`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: updateHiringsData._id,
          jobTitle: updateHiringsData.jobTitle,
          jobType: updateHiringsData.jobType,
          jobTech: updateHiringsData.jobTech,
          jobDiscription: updateHiringsData.jobDiscription,
          jobOpenings: updateHiringsData.jobOpenings,
        }),
      });
      if (res.status === 200) {
        window.alert("Hirings Updated!");
      } else {
        window.alert("Failed to Update Hirings");
      }
      setUpdateHiringsData({
        _id: "",
        jobTitle: "",
        jobType: "",
        jobTech: "",
        jobDiscription: "",
        jobOpenings: "",
      });
      getHirings();
      formSelect.current.options[0].selected = true;
    } catch (err) {
      console.log(err.message);
    }
  };

  const deleteHirings = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(`${apiUrl}/DeleteHirings`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: deleteHiring,
        }),
      });
      if (res.status === 200) {
        window.alert("Hiring Deleted!");
      } else {
        window.alert("Failed to Delete Hiring");
      }
      getHirings();
    } catch (err) {
      console.log(err.message);
    }
  };

  const createOptions = () => {
    if (!gotHirings) {
      return;
    }
    return gotHirings
      .map((curr, i) => {
        return (
          <option value={curr._id} key={i}>
            {curr.jobTitle}
          </option>
        );
      })
      .reverse();
  };

  const addHiringsForm = () => {
    return (
      <form
        method="POST"
        onSubmit={setHirings}
        // action={`${apiUrl}/SetHirings`}
      >
        <h2>Add Hirings</h2>
        <div className="admin-hirings-inputs">
          <input
            type="text"
            value={hiringsData.jobTitle}
            placeholder="Title"
            name="jobTitle"
            onChange={hangleChange}
            required
          />
          <label htmlFor="title">Title</label>
        </div>
        <div className="admin-hirings-inputs">
          <input
            type="text"
            value={hiringsData.jobType}
            placeholder="Type"
            name="jobType"
            onChange={hangleChange}
            required
          />
          <label htmlFor="title">Type</label>
        </div>
        <div className="admin-hirings-inputs">
          <input
            type="text"
            value={hiringsData.jobTech}
            placeholder="Technologies"
            name="jobTech"
            onChange={hangleChange}
            required
          />
          <label htmlFor="title">Technologies</label>
        </div>
        <div className="admin-hirings-inputs">
          <input
            type="text"
            value={hiringsData.jobOpenings}
            placeholder="Openings"
            name="jobOpenings"
            onChange={hangleChange}
            required
          />
          <label htmlFor="title">Openings</label>
        </div>
        <p style={{ fontSize: "0.6em", marginBottom: "-0.4rem" }}>
          clicking Enter or Newlink will {"'<br>'"} in the input below please
          dont change it{" "}
        </p>
        <div className="admin-hirings-inputs">
          <textarea
            type="text"
            value={hiringsData.jobDiscription}
            placeholder="Job Discription"
            name="jobDiscription"
            rows={8}
            onChange={(e) => {
              setHiringsData({
                ...hiringsData,
                jobDiscription: e.target.value.split("\n").join("<br>"),
              });
            }}
          />
          <label htmlFor="details">Job Discription</label>
        </div>
        <div className="admin-hirings-inputs">
          <input
            value={tempPass}
            type="password"
            placeholder="Admin Password"
            name="adminPass"
            onChange={(e) => {
              setTempPass(e.target.value);
              if (realAdminPass === e.target.value) {
                setPassVerified(true);
              } else {
                setPassVerified(false);
              }
            }}
            required
          />
          <label htmlFor="title">Admin Password</label>
          {tempPass.length !== 0 && !PassVerified ? (
            <p
              style={{ color: "red", fontSize: "0.75em", marginTop: "-0.5rem" }}
            >
              Wrong Password!
            </p>
          ) : (
            ""
          )}
        </div>
        <input type="submit" className="cta-button" disabled={!PassVerified} />
      </form>
    );
  };

  const updateHiringsForm = () => {
    return (
      <form onSubmit={updateHirings}>
        <h2>Update Hirings</h2>
        <div className="admin-hirings-inputs-select">
          <label htmlFor="hiringSelect">Select a Hiring:</label>
          <select
            ref={formSelect}
            name="hiringSelect"
            id="hiringSelect"
            onChange={(e) => {
              let x = e.target.value;
              let d = gotHirings.find((e) => {
                return e._id === x;
              });
              if (d) {
                setUpdateHiringsData(d);
              } else {
                setUpdateHiringsData({
                  _id: "",
                  jobTitle: "",
                  jobType: "",
                  jobTech: "",
                  jobDiscription: "",
                  jobOpenings: "",
                });
              }
            }}
          >
            <option defaultValue={true} value="">
              -- select an option --
            </option>
            {createOptions()}
          </select>
        </div>
        <div className="admin-hirings-inputs">
          <input
            type="text"
            value={updateHiringsData.jobTitle}
            placeholder="Title"
            name="jobTitle"
            onChange={hangleUpdateChange}
            required
          />
          <label htmlFor="title">Title</label>
        </div>
        <div className="admin-hirings-inputs">
          <input
            type="text"
            value={updateHiringsData.jobType}
            placeholder="Type"
            name="jobType"
            onChange={hangleUpdateChange}
            required
          />
          <label htmlFor="title">Type</label>
        </div>
        <div className="admin-hirings-inputs">
          <input
            type="text"
            value={updateHiringsData.jobTech}
            placeholder="Technologies"
            name="jobTech"
            onChange={hangleUpdateChange}
            required
          />
          <label htmlFor="title">Technologies</label>
        </div>
        <div className="admin-hirings-inputs">
          <input
            type="number"
            value={updateHiringsData.jobOpenings}
            placeholder="Openings"
            name="jobOpenings"
            onChange={hangleUpdateChange}
            required
          />
          <label htmlFor="title">Openings</label>
        </div>
        <p style={{ fontSize: "0.6em", marginBottom: "-0.4rem" }}>
          clicking Enter or Newlink will {"'<br>'"} in the input below please
          dont change it{" "}
        </p>
        <div className="admin-hirings-inputs">
          <textarea
            type="text"
            value={updateHiringsData.jobDiscription}
            placeholder="Job Discription"
            name="jobDiscription"
            rows={8}
            onChange={(e) => {
              setUpdateHiringsData({
                ...updateHiringsData,
                jobDiscription: e.target.value.split("\n").join("<br>"),
              });
            }}
          />
          <label htmlFor="details">Job Discription</label>
        </div>
        <div className="admin-hirings-inputs">
          <input
            value={tempPass}
            type="password"
            placeholder="Admin Password"
            name="adminPass"
            onChange={(e) => {
              setTempPass(e.target.value);
              if (realAdminPass === e.target.value) {
                setPassVerified(true);
              } else {
                setPassVerified(false);
              }
            }}
            required
          />
          <label htmlFor="title">Admin Password</label>
          {tempPass.length !== 0 && !PassVerified ? (
            <p
              style={{ color: "red", fontSize: "0.75em", marginTop: "-0.5rem" }}
            >
              Wrong Password!
            </p>
          ) : (
            ""
          )}
        </div>
        <input type="submit" className="cta-button" disabled={!PassVerified} />
      </form>
    );
  };
  const deleteHiringsForm = () => {
    return (
      <form onSubmit={deleteHirings}>
        <h2>Delete Hirings</h2>
        <div className="admin-hirings-inputs-select">
          <label htmlFor="hiringSelect">Select a Hiring:</label>
          <select
            // ref={formSelect}
            name="hiringSelect"
            onChange={(e) => {
              setDeleteHiring(e.target.value);
            }}
          >
            <option defaultValue={true} value="">
              -- select an option --
            </option>
            {createOptions()}
          </select>
        </div>
        <div className="admin-hirings-inputs">
          <input
            value={tempPass}
            type="password"
            placeholder="Admin Password"
            name="adminPass"
            onChange={(e) => {
              setTempPass(e.target.value);
              if (realAdminPass === e.target.value) {
                setPassVerified(true);
              } else {
                setPassVerified(false);
              }
            }}
            required
          />
          <label htmlFor="title">Admin Password</label>
          {tempPass.length !== 0 && !PassVerified ? (
            <p
              style={{ color: "red", fontSize: "0.75em", marginTop: "-0.5rem" }}
            >
              Wrong Password!
            </p>
          ) : (
            ""
          )}
        </div>
        <input type="submit" className="cta-button" disabled={!PassVerified} />
      </form>
    );
  };

  return (
    <div className="admin-hirings-contianer">
      <Header />
      <div className="container-title">
        <h2 className="title-primary">Hirings</h2>
        <h3 className="title-secondary">Admin</h3>
      </div>
      <div className="forms-container">
        {addHiringsForm()}
        {updateHiringsForm()}
        {deleteHiringsForm()}
      </div>
    </div>
  );
}

export default HiringPGAdmin;
