import React, { useEffect, useState } from "react";
import Flickity from "react-flickity-component";
import "./Work.css";
import NoPreview from "../../assets/img/NoPreview.png";
import LoadingPage from "../LoadingPage/LoadingPage";

const flickityOptions = {
  autoPlay: true,
  draggable: ">1",
  wrapAround: true,
  lazyLoad: true,
};

function Work() {
  const [caseStudiesData, setCaseStudiesData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const apiUrl = "https://api.edulade.com";
  const apiUrl = process.env.APIURL || "https://api.edulade.com";

  useEffect(() => {
    getCaseStudies();
  }, []);

  const getCaseStudies = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${apiUrl}/GetCaseStudies`);
      const data = await res.json();
      if (data.error) {
        setLoading(false);
        return;
      }
      setCaseStudiesData(data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };
  const CarouselItems = () => {
    return caseStudiesData
      .map((study, i) => {
        return (
          <a href={"/CaseStudies"} key={i}>
            <div className="carousel-cell">
              <img
                src={
                  study.previewImg
                    ? `data:${study.previewImg.type};base64, ${study.previewImg.data}`
                    : NoPreview
                }
                alt="Project"
                loading="lazy"
              />
              <div className="work-info">
                <h3>{study.title}</h3>
                <p>Click for more info</p>
              </div>
            </div>
          </a>
        );
      })
      .reverse();
  };
  return (
    <div className="work-container" id="OurWork">
      <div className="container-title">
        <h2 className="title-primary">Our</h2>
        <h3 className="title-secondary">Work</h3>
      </div>
      <div className="work-container-inner">
        <div className="work-main-carousel">
          <Flickity
            className={"carousel"}
            elementType={"div"}
            options={flickityOptions}
            disableImagesLoaded={false}
            reloadOnUpdate
            static
          >
            {CarouselItems()}
          </Flickity>
          {loading ? <LoadingPage /> : ""}
        </div>
        <a href={"/CaseStudies"} className="our-work-btn cta-button">
          View More
        </a>
      </div>
    </div>
  );
}

export default Work;
