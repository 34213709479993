import React, { useEffect, useState } from "react";
import "./HiringPG.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";
import Header from "../../Components/header/Header";
import JobApply from "../../Components/JobApply/JobApply";
const apiUrl = process.env.APIURL || "https://api.edulade.com";

function HiringPG({ showContact, setShowContact }) {
  const [hiringsData, setHiringsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showJobApply, setShowJobApply] = useState(false);
  const [currentTitle, setCurrentTitle] = useState(null);

  useEffect(() => {
    getHirings();
  }, []);

  const getHirings = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${apiUrl}/GetHirings`);
      const data = await res.json();
      if (data.error) {
        setLoading(false);
        return;
      }
      setHiringsData(data.reverse());
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  function createDiscription(job) {
    if (job.jobDiscription) {
      return { __html: job.jobDiscription };
    } else {
      return {
        __html: "No discription provided! <br> please contact for more details",
      };
    }
  }
  const createTech = (job) => {
    return (
      <span>
        {job.jobTech.map((e, i) => {
          return (
            <span className="job-tech-tag" key={i}>
              {" "}
              {job.jobTech[i]}
            </span>
          );
        })}
      </span>
    );
  };

  const displayJobs = () => {
    if (hiringsData.length === 0 && loading === false) {
      return <div className="no-hiring-data">Please check back later.</div>;
    }

    return hiringsData.map((job, i) => {
      return (
        <div className="job-item" key={i}>
          <input type="checkbox" name={i} id={i} />
          <label className="job-container" htmlFor={i}>
            <div className="job-container-inner">
              <div className="job-title">
                <FontAwesomeIcon icon={faBriefcase} />
                <h3>{job.jobTitle}</h3>
                <p className="job-type">{job.jobType}</p>
              </div>
              <div className="job-tech">
                <p
                  style={{
                    display: "flex",
                    gap: "0.1rem",
                    alignItems: "flex-start",
                  }}
                >
                  <span>Technologies: </span>
                  {job.jobTech.length > 0 ? createTech(job) : <span> N/A</span>}
                </p>
              </div>
              {/* <p className="job-caption">click for more details</p> */}
              <p
                style={{
                  fontSize: "0.65rem",
                  fontStyle: "italic",
                  fontWeight: 100,
                  paddingLeft: "1.25rem",
                }}
              >
                Click for more info
              </p>
            </div>
            <button
              style={{ minWidth: "115px" }}
              className="job-apply cta-button"
              onClick={(e) => {
                setShowJobApply(true);
                setCurrentTitle(job.jobTitle);
                e.preventDefault();
              }}
            >
              Apply Now
            </button>
          </label>
          <div className="job-discription">
            <p>Discription:</p>
            <br />
            <div
              style={{ fontWeight: 300 }}
              dangerouslySetInnerHTML={createDiscription(job)}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <Header />
      <JobApply
        showJobApply={showJobApply}
        setShowJobApply={setShowJobApply}
        currentTitle={currentTitle}
      />
      <div className="hiring-container">
        <div className="container-title">
          <h2 className="title-primary">Jobs Available</h2>
          <h3 className="title-secondary">in Edulade</h3>
        </div>
        <div className="hiring-container-inner">
          {/* <p style={{ textAlign: "center" }}>
            <i>This page is under construction</i>
          </p> */}
          {loading ? <LoadingPage /> : ""}
          {displayJobs()}
        </div>
      </div>
    </>
  );
}
export default HiringPG;
