import React, { useEffect } from "react";
import "./What.css";
import servImg1 from "../../assets/img/servImg1.png";
import servImg2 from "../../assets/img/servImg2.svg";
import servImg3 from "../../assets/img/servImg3.svg";
import servImg4 from "../../assets/img/servImg4.svg";
import servImg5 from "../../assets/img/servImg5.svg";
import servImg6 from "../../assets/img/servImg6.svg";

function What() {
  return (
    <div className="what-container" id="services">
      <div className="container-title">
        <h2 className="title-primary">What</h2>
        <h3 className="title-secondary">We Provide</h3>
      </div>
      <div className="what-container-inner">
        <div
          className="services-cont"
          style={{
            borderRight: "1px solid #0002",
          }}
        >
          <a
            href="/Services/Web-Software-Development"
            className="services-item"
          >
            <div className="service-img">
              <img src={servImg1} alt="img" loading="lazy" />
            </div>
            <div className="service-content">
              <h3 className="service-title">Web / Software Development</h3>
              <p className="service-discription">
                We have fast experience in software, web and product
                development. we serve small, mid and large enterprises and
                software product companies across multiple industries, including
                education, retail, financial services, manufacturing, healthcare
                and others.
              </p>
            </div>
          </a>
          <a href="/Services/Android-App-Development" className="services-item">
            <div className="service-img">
              <img src={servImg2} alt="img" loading="lazy" />
            </div>
            <div className="service-content">
              <h3 className="service-title">Android App Development</h3>
              <p className="service-discription">
                Edulade has professional android app developers that have both
                the expertise and experience to develop innovative, interactive,
                feature-packed and high-functioning android-based applications.
              </p>
            </div>
          </a> 
          <a href="/Services/IT-Consulting"  className="services-item">
            <div className="service-img">
              <img src={servImg3} alt="img" loading="lazy" />
            </div>
            <div className="service-content">
              <h3 className="service-title">IT Consulting</h3>
              <p className="service-discription">
                Use the expertise and deep technical/domain expertise of the
                best minds at edulade to create a comprehensive it strategy and
                road map for a digital and technological transformation of your
                organization. our strategic it/domain consulting will help you
                automate and digitalise operations, optimise the software
                portfolio, and implement the latest technologies.
              </p>
            </div>
          </a>
        </div>
        <div className="services-cont">
          <a href="/Services/Skill-Development" className="services-item">
            <div className="service-img">
              <img src={servImg4} alt="img" loading="lazy" />
            </div>
            <div className="service-content">
              <h3 className="service-title">Skill Development</h3>
              <p className="service-discription">
                Having industry specific job oriented 40+ training programs that
                consists of various niche and future it courses like artificial
                intelligen, machine learning, data science etc to non-it job
                oriented traning programs in aviation, hospitality, retail and
                travel & tours
              </p>
            </div>
          </a>
          <a href="/Services/Internship-programs" className="services-item">
            <div className="service-img">
              <img src={servImg5} alt="img" loading="lazy" />
            </div>
            <div className="service-content">
              <h3 className="service-title">Internship programs</h3>
              <p className="service-discription">
                Internships are an integral part of most of the professional
                courses that provide students the opportunity to apply their
                theoretical knowledge in real-life situations and problems.
                internships also assist the students to augment classroom
                instructions with hands-on exposure to the corporate world
                employment challenges and benefit students professionally.
              </p>
            </div>
          </a>
          <a
            href="/Services/Entrepreneurship-Development"
            className="services-item"
          >
            <div className="service-img">
              <img src={servImg6} alt="img" loading="lazy" />
            </div>
            <div className="service-content">
              <h3 className="service-title">Entrepreneurship Development</h3>
              <p className="service-discription">
                Students specifically looking for entrepreneurship competencies
                are well nurtured. this primarily consists of introduction to
                entrepreneurship, motivational training {">"} induce need for
                achievement, inject confidence and positive attitude. management
                skills {">"} imparting knowledge about finance, production,
                marketing, major dependencies key pre-requisites etc.
                understanding support system and procedures. fundamentals of
                feasibility study-technical, financial, organisational
                ,marketing and social aspects.
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default What;
