import React, { useEffect, useRef, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "./CaseStudiesAdmin.css";
import Header from "../../../Components/header/Header";
import NoPreview from "../../../assets/img/NoPreview.png";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginFileEncode,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

function CaseStudiesAdmin() {
  const formSelect = useRef();
  const [caseStudiesData, setCaseStudiesData] = useState({
    _id: "",
    title: "",
    clientName: "",
    details: "",
    link: "",
  });
  const [updateCaseStudiesData, setUpdateCaseStudiesData] = useState({
    _id: "",
    title: "",
    clientName: "",
    details: "",
    link: "",
  });
  const [deleteCaseStudy, setDeleteCaseStudy] = useState(null);
  const [gotCaseStudies, setGotCaseStudies] = useState();
  const [files, setFiles] = useState([]);
  const [upfile, setupfile] = useState(null);
  const [prog, setprog] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);
  const [tempPass, setTempPass] = useState("");
  const [PassVerified, setPassVerified] = useState(false);
  var realAdminPass = "Team@Edulade1234";
  if (process.env.ADMINPASS) realAdminPass = process.env.ADMINPASS;
  // const apiUrl = "https://eduladecombackend.vercel.app";
  const apiUrl = process.env.APIURL || "https://api.edulade.com";

  useEffect(() => {
    if (files) {
      if (files.length === 0) setFiles(null);
    }
  }, [files]);

  useEffect(() => {
    getCaseStudies();
  }, []);

  const getCaseStudies = async () => {
    try {
      const res = await fetch(`${apiUrl}/GetCaseStudies`);
      const data = await res.json();
      if (data.error) {
        return;
      }
      setGotCaseStudies(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const setCaseStudies = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(`${apiUrl}/SetCaseStudies`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: caseStudiesData.title,
          clientName: caseStudiesData.clientName,
          details: caseStudiesData.details,
          link: caseStudiesData.link,
          previewImage: files[0],
        }),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const updateCaseStudies = async (e) => {
    e.preventDefault();
    try {
      let file = null;
      if (files) {
        file = files[0];
      } else file = null;
      const res = await fetch(`${apiUrl}/UpdateCaseStudies`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: updateCaseStudiesData._id,
          title: updateCaseStudiesData.title,
          clientName: updateCaseStudiesData.clientName,
          details: updateCaseStudiesData.details,
          link: updateCaseStudiesData.link,
          previewImage: file,
        }),
      });
      if (res.status === 200) {
        window.alert("Case Study Updated!");
      } else {
        window.alert("Failed to Update Case Study");
      }
      getCaseStudies();
      setUpdateCaseStudiesData({
        _id: "",
        title: "",
        clientName: "",
        details: "",
        link: "",
      });
      formSelect.current.options[0].selected = true;
    } catch (err) {
      console.log(err.message);
    }
  };

  const deleteCaseStudies = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(`${apiUrl}/DeleteCaseStudies`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: deleteCaseStudy,
        }),
      });
      if (res.status === 200) {
        window.alert("Case Study Deleted!");
      } else {
        window.alert("Failed to Delete Case Study");
      }
      getCaseStudies();
    } catch (err) {
      console.log(err.message);
    }
  };

  const createOptions = () => {
    if (!gotCaseStudies) {
      return;
    }
    return gotCaseStudies
      .map((curr, i) => {
        return (
          <option value={curr._id} key={i}>
            {curr.title}
          </option>
        );
      })
      .reverse();
  };

  const logBase64 = (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      console.log(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const addCaseStudiesFrom = () => {
    return (
      <form method="POST" action={`${apiUrl}/SetCaseStudies`}>
        <h2>Add Work</h2>
        <div className="admin-case-studies-inputs">
          <input
            type="text"
            value={caseStudiesData.title}
            placeholder="Title"
            name="title"
            onChange={(e) => {
              setCaseStudiesData({ ...caseStudiesData, title: e.target.value });
            }}
            required
          />
          <label htmlFor="title">Title</label>
        </div>
        <div className="admin-case-studies-inputs">
          <input
            type="text"
            value={caseStudiesData.clientName}
            placeholder="Client Name"
            name="clientName"
            onChange={(e) => {
              setCaseStudiesData({
                ...caseStudiesData,
                clientName: e.target.value,
              });
            }}
            required
          />
          <label htmlFor="clientName">Client Name</label>
        </div>
        <div className="admin-case-studies-inputs">
          <input
            type="text"
            value={caseStudiesData.link}
            placeholder="Link to the project"
            name="link"
            onChange={(e) => {
              setCaseStudiesData({ ...caseStudiesData, link: e.target.value });
            }}
          />
          <label htmlFor="link">Link to the project</label>
        </div>
        <p style={{ fontSize: "0.6em", marginBottom: "-0.4rem" }}>
          clicking Enter or Newlink will {"'<br>'"} in the input below please
          dont change it{" "}
        </p>
        <div className="admin-case-studies-inputs">
          <textarea
            type="text"
            value={caseStudiesData.details}
            placeholder="Details"
            name="details"
            rows={8}
            onChange={(e) => {
              setCaseStudiesData({
                ...caseStudiesData,
                details: e.target.value.split("\n").join("<br>"),
              });
            }}
          />
          <label htmlFor="details">Details</label>
        </div>
        <p style={{ textAlign: "left", width: "99%", margin: "auto" }}>
          Preview Image:{" "}
        </p>
        <p className="upload-disclaimer">
          {" "}
          Max image size: 500KB, You can click{" "}
          <a href="https://tinypng.com/" target="_blank">
            here
          </a>{" "}
          to shink image size{" "}
        </p>
        <FilePond
          files={files}
          onupdatefiles={(e) => {
            setFiles(e);
          }}
          onaddfilestart={() => setFileLoading(true)}
          onaddfile={() => setFileLoading(false)}
          allowMultiple={false}
          name="files"
          checkValidity={true}
          allowFileEncode={true}
          allowFileSizeValidation={true}
          allowFileTypeValidation={true}
          labelMaxFileSizeExceeded={`File is too large`}
          acceptedFileTypes={[
            "image/png",
            "image/jpeg",
            "image/jpg",
            "image/webp",
          ]}
          maxFileSize={"500KB"}
          labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
        />
        <div className="admin-case-studies-inputs">
          <input
            value={tempPass}
            type="password"
            placeholder="Admin Password"
            name="adminPass"
            onChange={(e) => {
              setTempPass(e.target.value);
              if (realAdminPass === e.target.value) {
                setPassVerified(true);
              } else {
                setPassVerified(false);
              }
            }}
            required
          />
          <label htmlFor="title">Admin Password</label>
          {tempPass.length !== 0 && !PassVerified ? (
            <p
              style={{ color: "red", fontSize: "0.75em", marginTop: "-0.5rem" }}
            >
              Wrong Password!
            </p>
          ) : (
            ""
          )}
        </div>
        <input
          type="submit"
          className="cta-button"
          disabled={fileLoading || !PassVerified}
        />
      </form>
    );
  };
  const updateCaseStudiesForm = () => {
    return (
      <form onSubmit={updateCaseStudies}>
        <h2>Update Work</h2>
        <div className="admin-case-studies-inputs-select">
          <label htmlFor="caseSelect">Select a Work:</label>
          <select
            ref={formSelect}
            name="caseSelect"
            id="caseSelect"
            onChange={(e) => {
              let x = e.target.value;
              let d = gotCaseStudies.find((e) => {
                return e._id === x;
              });
              if (d) {
                setUpdateCaseStudiesData(d);
              } else {
                setUpdateCaseStudiesData({
                  _id: "",
                  title: "",
                  clientName: "",
                  details: "",
                  link: "",
                });
              }
            }}
          >
            <option defaultValue={true} value="">
              -- select an option --
            </option>
            {createOptions()}
          </select>
        </div>
        <div className="admin-case-studies-inputs">
          <input
            type="text"
            value={updateCaseStudiesData.title}
            placeholder="Title"
            name="title"
            onChange={(e) => {
              setUpdateCaseStudiesData({
                ...updateCaseStudiesData,
                title: e.target.value,
              });
            }}
            required
          />
          <label htmlFor="title">Title</label>
        </div>
        <div className="admin-case-studies-inputs">
          <input
            type="text"
            value={updateCaseStudiesData.clientName}
            placeholder="Client Name"
            name="clientName"
            onChange={(e) => {
              setUpdateCaseStudiesData({
                ...updateCaseStudiesData,
                clientName: e.target.value,
              });
            }}
            required
          />
          <label htmlFor="clientName">Client Name</label>
        </div>
        <div className="admin-case-studies-inputs">
          <input
            type="text"
            value={updateCaseStudiesData.link}
            placeholder="Link to the project"
            name="link"
            onChange={(e) => {
              setUpdateCaseStudiesData({
                ...updateCaseStudiesData,
                link: e.target.value,
              });
            }}
          />
          <label htmlFor="link">Link to the project</label>
        </div>
        <p style={{ fontSize: "0.6em", marginBottom: "-0.4rem" }}>
          clicking Enter or Newlink will {"'<br>'"} in the input below please
          dont change it{" "}
        </p>
        <div className="admin-case-studies-inputs">
          <textarea
            type="text"
            value={updateCaseStudiesData.details}
            placeholder="Details"
            name="details"
            rows={8}
            onChange={(e) => {
              setUpdateCaseStudiesData({
                ...updateCaseStudiesData,
                details: e.target.value.split("\n").join("<br>"),
              });
            }}
          />
          <label htmlFor="details">Details</label>
        </div>
        <p
          style={{
            textAlign: "left",
            width: "99%",
            margin: "auto",
            marginTop: "1rem",
          }}
        >
          Preview Image:{" "}
        </p>
        <img
          src={
            updateCaseStudiesData.previewImg
              ? `data:${updateCaseStudiesData.previewImg.type};base64, ${updateCaseStudiesData.previewImg.data}`
              : NoPreview
          }
          alt="Case Study img"
        />
        <p
          style={{
            textAlign: "left",
            width: "99%",
            margin: "auto",
            marginTop: "1rem",
          }}
        >
          New Preview Image(optional):
        </p>
        <p className="upload-disclaimer">
          {" "}
          Max image size: 500KB, You can click{" "}
          <a href="https://tinypng.com/" target="_blank">
            here
          </a>{" "}
          to shink image size{" "}
        </p>
        <FilePond
          files={files}
          onupdatefiles={setFiles}
          onaddfilestart={() => setFileLoading(true)}
          onaddfile={() => setFileLoading(false)}
          allowMultiple={false}
          name="files"
          checkValidity={true}
          allowFileEncode={true}
          allowFileSizeValidation={true}
          allowFileTypeValidation={true}
          labelMaxFileSizeExceeded={`File is too large`}
          acceptedFileTypes={[
            "image/png",
            "image/jpeg",
            "image/jpg",
            "image/webp",
          ]}
          maxFileSize={"500KB"}
          labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
        />
        <div className="admin-case-studies-inputs">
          <input
            value={tempPass}
            type="password"
            placeholder="Admin Password"
            name="adminPass"
            onChange={(e) => {
              setTempPass(e.target.value);
              if (realAdminPass === e.target.value) {
                setPassVerified(true);
              } else {
                setPassVerified(false);
              }
            }}
            required
          />
          <label htmlFor="title">Admin Password</label>
          {tempPass.length !== 0 && !PassVerified ? (
            <p
              style={{ color: "red", fontSize: "0.75em", marginTop: "-0.5rem" }}
            >
              Wrong Password!
            </p>
          ) : (
            ""
          )}
        </div>
        <input
          type="submit"
          className="cta-button"
          disabled={fileLoading || !PassVerified}
        />
      </form>
    );
  };
  const deleteCaseStudiesForm = () => {
    return (
      <form onSubmit={deleteCaseStudies}>
        <h2>Delete Work</h2>
        <div className="admin-case-studies-inputs-select">
          <label htmlFor="caseSelect">Select a Work:</label>
          <select
            // ref={formSelect}
            name="caseSelect"
            onChange={(e) => {
              setDeleteCaseStudy(e.target.value);
            }}
          >
            <option defaultValue={true} value="">
              -- select an option --
            </option>
            {createOptions()}
          </select>
        </div>

        <div className="admin-case-studies-inputs">
          <input
            value={tempPass}
            type="password"
            placeholder="Admin Password"
            name="adminPass"
            onChange={(e) => {
              setTempPass(e.target.value);
              if (realAdminPass === e.target.value) {
                setPassVerified(true);
              } else {
                setPassVerified(false);
              }
            }}
            required
          />
          <label htmlFor="title">Admin Password</label>
          {tempPass.length !== 0 && !PassVerified ? (
            <p
              style={{ color: "red", fontSize: "0.75em", marginTop: "-0.5rem" }}
            >
              Wrong Password!
            </p>
          ) : (
            ""
          )}
        </div>
        <input
          type="submit"
          className="cta-button"
          disabled={fileLoading || !PassVerified}
        />
      </form>
    );
  };

  return (
    <div className="admin-case-studies-contianer">
      <Header />
      <div className="container-title">
        <h2 className="title-primary">Case Studies</h2>
        <h3 className="title-secondary">Admin</h3>
      </div>
      <div className="forms-container">
        {/* POST */}
        {addCaseStudiesFrom()}

        {/* UPDATE */}
        {updateCaseStudiesForm()}

        {/* DELETE */}
        {deleteCaseStudiesForm()}
      </div>
    </div>
  );
}

export default CaseStudiesAdmin;
