import React from "react";
import "./Admin.css";
import Header from "../../Components/header/Header";
import { Link } from "react-router-dom";

function Admin() {
  return (
    <div className="admin-container">
      <Header />
      <div className="container-title">
        <h2 className="title-primary">Admin</h2>
        <h3 className="title-secondary">panel</h3>
      </div>
      <div className="admin-container-inner">
        <Link to={"/Admin/CaseStudies"} className="admin-items cta-button">
          Our Work
        </Link>
        <Link to={"/Admin/Hirings"} className="admin-items cta-button">
          Hirings
        </Link>
      </div>
    </div>
  );
}

export default Admin;
