import React from "react";
import { Link } from "react-router-dom";
import "./NotFound.css";

function NotFound() {
  return (
    <div className="notfound-container">
      <div className="notfound-container-inner">
        <h1 className="back-number">404</h1>
        <div className="front-content">
          <h1>Page NotFound!</h1>
          <p>click below to go to Home page</p>
          <Link className="cta-button" to={"/"}>
           {">"} Home {"<"}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
